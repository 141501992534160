body {
  margin: 0;
  font-family: "Segoe UI" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;

  /* -ms-overflow-style: none; 
  scrollbar-width: none;  */
}

/* body::-webkit-scrollbar {
  display: none; 
} */

button,
a {
  transform: scale(1) !important;
}

button:active,
a:active {
  transform: scale(0.95) !important;
  transition: transform 0.1s ease-in-out;
}

@font-face {
  font-family: "Segoe UI";
  src: local("Segoe UI"), local("SegoeUI"),
    url("../fonts/SegoeUI.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Segoe UI";
  src: local("Segoe UI Semibold"), local("SegoeUI-SemiBold"),
    url("../fonts/SegoeUI-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Segoe UI";
  src: local("Segoe UI Light"), local("SegoeUI-Light"),
    url("../fonts/SegoeUI-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Segoe UI";
  src: local("Segoe UI Bold"), local("SegoeUI-Bold"),
    url("../fonts/SegoeUI-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Segoe UI";
  src: local("Segoe UI Italic"), local("SegoeUI-Italic"),
    url("../fonts/SegoeUI-Italic.woff2") format("woff2");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

.loadingPage {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-big {
  width: 50px;
  height: 50px;
}

.loader {
  width: 30px;
  height: 30px;
}

.loader-double {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50px;
  border: 3px solid transparent;
  border-top-color: #3498db;
  border-bottom-color: #3498db;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.loader-big.loader-double:after,
.loader.loader-double:before,
.loader.loader-double:after {
  top: 5px;
  left: 5px;
  bottom: 5px;
  right: 5px;
}

.loader-big.loader-double:before {
  top: 15px;
  left: 15px;
  bottom: 15px;
  right: 15px;
}

.loader-double:before,
.loader-double:after {
  content: "";
  position: absolute;
  border-radius: 50px;
  border: 6px solid transparent;
  border-top-color: #3498db;
  border-bottom-color: #3498db;
  filter: alpha(opacity=6);
  -khtml-opacity: 0.6;
  -moz-opacity: 0.6;
  opacity: 0.6;
  -webkit-animation: spinreverse 1s linear infinite;
  animation: spinreverse 1s linear infinite;
  -webkit-transition: -webkit-transform 1s ease-in-out;
}
.loader-double:before {
  border-width: 2px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinreverse {
  0% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

@-webkit-keyframes spinreverse {
  0% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

@-moz-keyframes spinreverse {
  0% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
